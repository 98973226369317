import { InterviewPublic, noop, ProjectPublic } from "app-types";
import { FC, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useSelector } from "react-redux";
import { RetellWebClient } from "retell-client-js-sdk";
import { Button, ButtonVariantsEnum, ModalVariantsEnum, SimpleModal } from "ui";
import { createAxiosInstance } from "../../api/axiosConfig";
import { InterviewMicrophoneControls } from "../interview/interviewMicrophoneControls";
import { InterviewMicrophoneSetup } from "../interview/interviewMicrophoneSetup";
import { InterviewCompletedSection } from "../interviewCompleted/interviewCompletedSection";
import { selectInterviewToken } from "../transcriptFragments/transcriptFragmentsSlice";
import { CallTicker } from "./callTicker";

interface VoiceAgentInterviewerProps {
  interview: InterviewPublic;
  project: ProjectPublic;
}

const retellWebClient = new RetellWebClient();

// The minimum length of time for an interview to be considered completed
const MINIMUM_INTERVIEW_LENGTH_SECONDS = 20;

export const VoiceAgentInterviewer: FC<VoiceAgentInterviewerProps> = ({
  interview,
  project,
}) => {
  const [isCalling, setIsCalling] = useState(false);
  const [hasEndedCall, setHasEndedCall] = useState(false);
  const [isEndCallModalOpen, setIsEndCallModalOpen] = useState(false);
  const [callStartTime, setCallStartTime] = useState<number | null>(null);
  const [callDuration, setCallDuration] = useState<number | null>(null);
  const isInterviewTooShort =
    callDuration && callDuration < MINIMUM_INTERVIEW_LENGTH_SECONDS;

  const [microphoneError, setMicrophoneError] = useState<Error | null>(null);
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);
  const [microphoneIdToSwitchTo, setMicrophoneIdToSwitchTo] = useState<
    string | undefined
  >(undefined);
  const [micPermission, setMicPermission] = useState<
    PermissionState | undefined
  >();
  const interviewToken = useSelector(selectInterviewToken);
  const [retellError, setRetellError] = useState<string | null>(null);

  useEffect(() => {
    retellWebClient.on("call_started", () => {
      setIsCalling(true);
      setRetellError(null);
    });

    retellWebClient.on("call_ended", () => {
      setIsCalling(false);
      setHasEndedCall(true);
    });

    retellWebClient.on("error", (error) => {
      console.error("An error occurred:", error);
      setRetellError(
        "An error occurred during the call. Please try again or email support@alpharun.com."
      );
      retellWebClient.stopCall();
      setIsCalling(false);
    });
  }, []);

  const startCall = async () => {
    setIsCalling(true);
    setHasEndedCall(false);
    setCallDuration(null);
    setCallStartTime(Date.now());
    setRetellError(null);

    try {
      // Retrieve a retell access token for the call
      const axiosInstance = createAxiosInstance(interviewToken);
      const response = await axiosInstance.post(
        "interview/initiate-voice-call"
      );
      const callToken = response.data.call_token;
      await retellWebClient.startCall({
        accessToken: callToken,
        captureDeviceId: microphoneIdToSwitchTo,
      });
    } catch (error) {
      console.error("Failed to start call:", error);
      setRetellError(
        "Failed to start the call. Please try again or email support@alpharun.com."
      );
      setIsCalling(false);
    }
  };

  const stopCall = () => {
    retellWebClient.stopCall();
    setIsCalling(false);
    setHasEndedCall(true);
    setIsEndCallModalOpen(false);

    if (callStartTime) {
      setCallDuration((Date.now() - callStartTime) / 1000); // Convert to seconds
    }
  };

  const renderContent = () => {
    if (micPermission !== "granted" || microphoneError) {
      return (
        <div className="flex flex-col justify-center items-center min-h-[140px]">
          <InterviewMicrophoneSetup
            microphones={microphones}
            setMicrophones={setMicrophones}
            microphoneError={microphoneError}
            setMicrophoneError={setMicrophoneError}
            micPermission={micPermission}
            setMicPermission={setMicPermission}
          />
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center space-y-4">
        <div className="flex flex-col items-center space-y-3 pt-4">
          {isCalling ? (
            <>
              <CallTicker />
              <div className="pt-2">
                <Button
                  onClick={() => setIsEndCallModalOpen(true)}
                  variant={ButtonVariantsEnum.Warning}
                  label="End Call"
                />
              </div>
              <div className="text-sm text-gray-600">
                When you've completed your interview you can end the call.
              </div>
            </>
          ) : (
            <>
              {isInterviewTooShort ? (
                <p className="text-sm text-gray-600">
                  It looks like you didn't complete your interview. You can
                  restart your interview when you're ready.
                </p>
              ) : null}
              {retellError && (
                <p className="text-sm text-red-600 mb-2">{retellError}</p>
              )}
              <Button
                onClick={() => {
                  startCall();
                }}
                variant={ButtonVariantsEnum.Primary}
                label={
                  isInterviewTooShort ? "Restart Interview" : "Start Interview"
                }
              />
            </>
          )}
        </div>
        {isDesktop && (
          <div className="pt-8 items-start flex flex-row w-full">
            <InterviewMicrophoneControls
              stopAnswering={noop} // We don't allow user to edit their microphone during a live call
              microphones={microphones}
              setMicrophones={setMicrophones}
              currentMicrophoneDeviceId={undefined}
              microphoneIdToSwitchTo={microphoneIdToSwitchTo}
              setMicrophoneIdToSwitchTo={setMicrophoneIdToSwitchTo}
              setMicrophoneError={setMicrophoneError}
              isRecordingAnswer={isCalling}
              isReadOnly={isCalling}
            />
          </div>
        )}
      </div>
    );
  };

  // Case where the user has completed a voice interview of at least 20 seconds
  if (hasEndedCall && !isInterviewTooShort) {
    return (
      <InterviewCompletedSection interview={interview} project={project} />
    );
  }

  return (
    <div className="flex flex-col p-8">
      <h1 className="text-3xl font-semibold mb-4 text-gray-800">{`Welcome`}</h1>
      <div className="text-sm text-gray-800">
        <div className="mb-4 text-gray-700">
          {
            "We appreciate your interest in this opportunity and look forward to learning more about you."
          }
        </div>
      </div>
      {renderContent()}
      <SimpleModal
        isOpen={isEndCallModalOpen}
        variant={ModalVariantsEnum.Warning}
        title="End Call"
        subtitle="Are you sure you want to end the interview?"
        confirmButtonText="End Interview"
        onCancel={() => setIsEndCallModalOpen(false)}
        onConfirm={stopCall}
      />
    </div>
  );
};
